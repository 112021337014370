





































































import {Component, Vue} from "vue-property-decorator";
import {namespace} from "vuex-class";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import FillableDocumentFilter from "@/dto/archive/FillableDocumentFilter";
import {FillableDocumentType} from "@/dto/archive/FillableDocumentType";
import DocumentService from "@/services/DocumentService";
import FillableDocumentDataDTO from "@/dto/archive/FillableDocumentDataDTO";
import RequestW9Modal from "@/components/archive/w9/RequestW9Modal.vue";
import Workspaces from "@/state/Workspaces";
import RequestW9ForSelfFillingModal from "@/components/archive/w9/RequestW9ForSelfFillingModal.vue";
import RouteNames from "@/router/RouteNames";
import {FillableDocumentStatus} from "@/dto/archive/FillableDocumentStatus";

const AppModule = namespace("App");
const Auth = namespace("Auth");

@Component({
	methods: {
		RouteNames() {
			return RouteNames
		}
	}
})
export default class W9List extends Vue {

    private successful = false;

    private message = "";

    @AppModule.State
    private loading!: boolean;

    @AppModule.Action
    private startLoading!: () => void;

    @AppModule.Action
    private stopLoading!: () => void;

    private forms: Array<FillableDocumentDataDTO> = [];

    mounted() {
        this.loadForms()
    }

    showModal() {
        this.$modal.show(
            RequestW9Modal,
            {
                companyId: Workspaces.getCurrent.id,
                onSaved: () => this.loadForms()
            },
            {width: "50%", height: "auto"}
        );
    }

	selfFilling() {
		this.$modal.show(
			RequestW9ForSelfFillingModal,
			{
				companyId: Workspaces.getCurrent.id,
				onSaved: () => this.loadForms()
			}
		);
	}

    loadForms(){
        this.startLoading();
        this.message = "";
        const filter = new FillableDocumentFilter();
        filter.requestingCompanyId = Workspaces.getCurrent.id;
        filter.documentType = FillableDocumentType.W_9;
        return DocumentService.getFillableByFilter(filter).then(
            success => this.forms = success.data.data,
            error => this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string
        ).then(() => this.stopLoading());
    }

	private processEvent(form: FillableDocumentDataDTO) {
		if (form.publicKey != null) {
			this.$router.push(
				{
					name: form.companyName != null ? RouteNames.PUBLIC_FORMS_W9_COMPANY : RouteNames.PUBLIC_FORMS_W9_PERSON,
					params: {
						publicId: `${form.publicKey}`
					}
				}
			)
		} else {
			Vue.prototype.$docUtils.download(form.signedFile!.id)
		}
	}

}
