




































import {Component, Vue} from "vue-property-decorator";
import PortalSelect from "@/components/common/PortalSelect.vue";
import PortalCheckbox from "@/components/common/PortalCheckbox.vue";
import PortalInput from "@/components/common/PortalInput.vue";
import CreateW9RequestDTO from "@/dto/archive/CreateW9RequestDTO";
import {namespace} from "vuex-class";
import {ifValid} from "@/utils/ComponentUtils";
import DocumentService from "@/services/DocumentService";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import {CounterpartyType} from "@/constants/CounterpartyType";
import RouteNames from "@/router/RouteNames";
import Workspaces from "@/state/Workspaces";

const AppModule = namespace("App");

@Component({
	components: {PortalInput, PortalCheckbox, PortalSelect}
})
export default class RequestW9ForSelfFillingModal extends Vue {
	private successful = false;

	private message = "";

	@AppModule.Action
	private startLoading!: () => void

	@AppModule.Action
	private stopLoading!: () => void

	private request = new CreateW9RequestDTO();

	private save() {
		ifValid(this, () => {
			this.startLoading();
			this.request.selfFilling = true;
			this.request.employerId = Workspaces.getCurrent.id;
			this.request.counterpartyType = CounterpartyType.COMPANY;
			DocumentService.registerW9(this.request).then(
				(res) => {
					this.successful = true;
					this.$modal.hideAll();
					this.$router.push(
						{
							name: RouteNames.PUBLIC_FORMS_W9_COMPANY,
							params: {
								publicId: `${res.data.publicKey}`
							}
						}
					)
				},
				error => {
					this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
				}
			).then(() => this.stopLoading());
		})
	}
}

